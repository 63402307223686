<template>
    <section class="section section__news-details">
        <div class="container">
            <div class="section__content">
                <h1 class="section__title title" v-if="news.title">{{ news.title }}</h1>
                <div class="news__content content" v-if="news.description" v-html="news.description">
                </div>
                  <ul class="news__photo-list list" v-if="news.photos">
                    <li
                        class="news__photo-item"
                        v-for="photo in news.photos"
                        :key="photo.id"
                        :id="photo.id"
                    >
                        <div class="news__photo-wrap">
                            <a class="news__photo" @click="openModal({'src': `${photo.url}`, 'alt': photo.name})">
                                <img 
                                :src="`${photo.url}`" 
                                class="news__img"
                                :alt="photo.name" />
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
        
    export default {
        data() {
            return {
                news: {}
            }
        },
        computed: {
            ...mapGetters(['getNewsId']),
        },
        methods: {
            ...mapActions(["openModal"]),
        },
        created() {
            this.news = this.getNewsId(+this.$route.params.id)
        }
    }
</script>

<style scoped>

</style>